<template>
    <div class="page home">
        <div class="title">首页</div>
        <div class="header_top flex" style="color: #fff;">
            <div class="right flex">
                <a-select v-model="allValue" @change="timeChange(allValue, 1)">
                    <a-select-option v-for="(item, index) in timeArr" :key="index" :value="item.value">{{ item.label
                        }}</a-select-option>
                </a-select>
                <a-date-picker v-model="timeStart" @change="onChange1($event, 1)" />至<a-date-picker
                    :disabled-date="disabledDate" @change="onChange1($event, 2)" />
            </div>
        </div>
        <div class="header">
            <div class="flex title_small">
                <img class="" src="@/assets/imgs/wap/data.png" alt="" />
                <span class="">数据概览</span>
            </div>
            <div class="client_con">
                <div class="client_con_flex">
                    <span class="">￥{{ $utils.numberToWan(home.payroll?.payrollCurrent.money) }}<br /><span
                            class="txt ">本期工资</span></span>
                </div>
                <div class="client_con_flex">
                    <span>￥{{ $utils.numberToWan(home.payroll?.payrollAll.money) }}<br /><span
                            class="txt ">全部工资</span></span>
                </div>
                <div class="client_con_flex">
                    <span>￥{{ $utils.numberToWan(home.payroll?.payrollUnpaid.money) }}<br /><span
                            class="txt ">未发工资</span></span>
                </div>
            </div>
            <div class="client_con" style="background: #ECFBFF;">
                <div class="client_con_flex">
                    <span style="color: #107FFF;">{{ home.corp?.corpAdd.num }}<br /><span class="txt ">本期新增客户数
                        </span></span>
                </div>
                <div class="client_con_flex">
                    <span style="color: #107FFF;">{{ home.corp?.corpFollow.num }}<br /><span
                            class="txt ">本期跟进客户数</span></span>
                </div>
                <div class="client_con_flex">
                    <span style="color: #107FFF;">{{ home.corp?.corpAll.num }}<br /><span
                            class="txt ">全部客户数</span></span>
                </div>
            </div>
            <div class="client_con" style="background: #E4F0FE;">
                <div class="client_con_flex" style="width: 120px;">
                    <span style="color:#000000;">{{ home.order?.orderCurrent.num }}<br /><span class="txt ">本期订单数
                        </span></span>
                </div>
                <div class="client_con_flex" style="width: 120px;">
                    <span style="color:#000000;">￥{{ $utils.numberToWan(home.order?.orderCurrent.amount) }}<br /><span
                            class="txt ">本期订单金额</span></span>
                </div>
                <div class="client_con_flex" style="width: 120px;">
                    <span style="color:#000000;">{{ home.order?.orderAll.num }}<br /><span
                            class="txt ">全部订单数</span></span>
                </div>
                <div class="client_con_flex" style="width: 120px;">
                    <span style="color:#000000;">￥{{ $utils.numberToWan(home.order?.orderAll.amount) }}<br /><span
                            class="txt ">全部订单金额</span></span>
                </div>
            </div>
            <div class="client_con" style="background: #EBEFFA;">
                <div class="client_con_flex" style="width: 120px;">
                    <span style="color:#000000;">{{ home.payment?.paymentCurrent.num }}<br /><span class="txt ">本期收款数
                        </span></span>
                </div>
                <div class="client_con_flex" style="width: 120px;">
                    <span style="color:#000000;">￥{{ $utils.numberToWan(home.payment?.paymentCurrent.amount)
                        }}<br /><span class="txt ">本期收款金额</span></span>
                </div>
                <div class="client_con_flex" style="width: 120px;">
                    <span style="color:#000000;">{{ home.payment?.paymentAll.num }}<br /><span
                            class="txt ">全部收款数</span></span>
                </div>
                <div class="client_con_flex" style="width: 120px;">
                    <span style="color:#000000;">￥{{ $utils.numberToWan(home.payment?.paymentAll.amount) }}<br /><span
                            class="txt ">全部收款金额</span></span>
                </div>
            </div>
            <div class="client_con" style="background: rgba(255, 211, 186, .2);">
                <div class="client_con_flex" style="width: 120px;">
                    <span style="color:#000000;">{{ home.purchase?.purchaseCurrent.num }}<br /><span class="txt ">本期进件数
                        </span></span>
                </div>
                <div class="client_con_flex" style="width: 120px;">
                    <span style="color:#000000;">{{ home.purchase?.purchaseAll.num }}<br /><span
                            class="txt ">全部进件数</span></span>
                </div>
            </div>
        </div>
        <div class="flex title_small" style="margin:10px 20px ">
            <img class="" src="@/assets/imgs/wap/rank1.png" alt="" />
            <span class="">销售排行榜</span>
        </div>
        <div class="div_con ranking">
            <div class="div_top flex" style=" padding: 15px 0;height: auto;width: 100%;">
                <div class="right flex" style="padding: 0 15px;border-bottom: 4px solid #eee;">
                    <a-select v-model="rankValue" @change="timeChange(rankValue, 2)">
                        <a-select-option v-for="(item, index) in timeArr" :key="index" :value="item.value">{{ item.label
                            }}</a-select-option>
                    </a-select>
                    <a-date-picker v-model="timeStart2" @change="onChange2($event, 1)" />至<a-date-picker
                        :disabled-date="disabledDate2" @change="onChange2($event, 2)" />
                </div>
            </div>
            <div class="ranking_con ">
                <div class="datas-common" style="height: 600px;" ref="main" id="main11"></div>
                <img class="ranking_l" src="@/assets/imgs/wap/left.png" alt="" />
                <img class="ranking_r" src="@/assets/imgs/wap/right1.png" alt="" />
            </div>
        </div>
        <div class="flex title_small" style="margin:10px 20px ">
            <img class="" src="@/assets/imgs/wap/dynamic1.png" alt="" />
            <span class="">订单统计</span>
        </div>

        <div class="div_top1 flex">
            <div class="right flex">
                <a-select v-model="orderValue" @change="timeChange(orderValue, 3)">
                    <a-select-option v-for="(item, index) in timeArr" :key="index" :value="item.value">{{ item.label
                        }}</a-select-option>
                </a-select>
                <a-date-picker v-model="timeStart3" @change="onChange3($event, 1)" />至<a-date-picker
                    :disabled-date="disabledDate3" @change="onChange3($event, 2)" />
            </div>
        </div>
        <div class="div_con1 ">
            <div class="div_top flex">
                <span>本期订单数</span>
                <span>{{ news.order?.orderCurrent.num }}</span>
            </div>
            <div class="div_top flex">
                <span>本期订单金额</span>
                <span>￥{{ $utils.numberToWan(news.order?.orderCurrent.amount) }}</span>
            </div>
            <div class="div_top flex">
                <span>总订单数</span>
                <span>{{ news.order?.orderAll.num }}</span>
            </div>
            <div class="div_top flex">
                <span>总订单金额</span>
                <span>￥{{ $utils.numberToWan(news.order?.orderAll.amount) }}</span>
            </div>
        </div>
        <div class="div_con1 ">
            <div class="div_top flex">
                <span>本期进件订单数</span>
                <span>{{ news.purchase?.purchaseCurrent.num }}</span>
            </div>
            <div class="div_top flex">
                <span>本期进件订单金额</span>
                <span>￥{{ $utils.numberToWan(news.purchase?.purchaseCurrent.amount) }}</span>
            </div>
            <div class="div_top flex">
                <span>进件订单总数</span>
                <span>{{ news.purchase?.purchaseAll.num }}</span>
            </div>
            <div class="div_top flex">
                <span>进件订单总金额</span>
                <span>￥{{ $utils.numberToWan(news.purchase?.purchaseAll.amount) }}</span>
            </div>
        </div>
        <div class="div_con1 ">
            <div class="div_top flex">
                <span>本期培训订单数</span>
                <span>{{ news.train?.trainCurrent.num }}</span>
            </div>
            <div class="div_top flex">
                <span>本期培训订单金额</span>
                <span>￥{{ $utils.numberToWan(news.train?.trainCurrent.amount) }}</span>
            </div>
            <div class="div_top flex">
                <span>培训订单总数</span>
                <span>{{ news.train?.trainAll.num }}</span>
            </div>
            <div class="div_top flex">
                <span>培训定订单金额</span>
                <span>￥{{ $utils.numberToWan(news.train?.trainAll.amount) }}</span>
            </div>
        </div>
        <div class="flex title_small" style="margin:10px 20px ">
            <img class="" src="@/assets/imgs/wap/sk.png" alt="" />
            <span class="">收款统计</span>
        </div>
        <div class="div_top1 flex">
            <div class="right flex">
                <a-select v-model="statisticsValue" @change="timeChange(statisticsValue, 4)">
                    <a-select-option v-for="(item, index) in timeArr" :key="index" :value="item.value">{{ item.label
                        }}</a-select-option>
                </a-select>
                <a-date-picker v-model="timeStart4" @change="onChange4($event, 1)" />至<a-date-picker
                    :disabled-date="disabledDate4" @change="onChange4($event, 2)" />
            </div>
        </div>
        <div class="div_con1 ">
            <div class="div_top flex">
                <span>本期收款数</span>
                <span>{{ task.payment?.paymentCurrent.num }}</span>
            </div>
            <div class="div_top flex">
                <span>本期收款金额</span>
                <span>￥{{ $utils.numberToWan(task.payment?.paymentCurrent.amount) }}</span>
            </div>
            <div class="div_top flex">
                <span>收款总数</span>
                <span>{{ task.payment?.paymentAll.num }}</span>
            </div>
            <div class="div_top flex">
                <span>收款总金额</span>
                <span class="or">￥{{ $utils.numberToWan(task.payment?.paymentAll.amount) }}</span>
            </div>
            <div class="div_top flex">
                <span>待收款数</span>
                <span>{{ task.payment?.pendingPayment.num }}</span>
            </div>
            <div class="div_top flex">
                <span>待收款金额</span>
                <span>￥{{ $utils.numberToWan(task.payment?.pendingPayment.amount) }}</span>
            </div>
        </div>
        <div class="div_con1 ">
            <div class="div_top flex">
                <span>本期进件收款数</span>
                <span>{{ task.purchase?.purchaseCurrent.num }}</span>
            </div>
            <div class="div_top flex">
                <span>本期进件收款金额</span>
                <span>￥{{ $utils.numberToWan(task.purchase?.purchaseCurrent.amount) }}</span>
            </div>
            <div class="div_top flex">
                <span>进件收款总数</span>
                <span>{{ task.purchase?.purchaseAll.num }}</span>
            </div>
            <div class="div_top flex">
                <span>进件收款总金额</span>
                <span class="or">￥{{ $utils.numberToWan(task.purchase?.purchaseAll.amount) }}</span>
            </div>
            <div class="div_top flex">
                <span>进件待收款数</span>
                <span>{{ task.purchase?.pendingPayment.num }}</span>
            </div>
            <div class="div_top flex">
                <span>进件待收款金额</span>
                <span>￥{{ $utils.numberToWan(task.purchase?.pendingPayment.amount) }}</span>
            </div>
        </div>
        <div class="div_con1 ">
            <div class="div_top flex">
                <span>本期培训收款数</span>
                <span>{{ task.train?.trainCurrent.num }}</span>
            </div>
            <div class="div_top flex">
                <span>本期培训收款金额</span>
                <span>￥{{ $utils.numberToWan(task.train?.trainCurrent.amount) }}</span>
            </div>
            <div class="div_top flex">
                <span>培训收款总数</span>
                <span>{{ task.train?.trainAll.num }}</span>
            </div>
            <div class="div_top flex">
                <span>培训收款总金额</span>
                <span class="or">￥{{ $utils.numberToWan(task.train?.trainAll.amount) }}</span>
            </div>
            <div class="div_top flex">
                <span>培训待收款数</span>
                <span>{{ task.train?.pendingPayment.num }}</span>
            </div>
            <div class="div_top flex">
                <span>培训待收款金额</span>
                <span>￥{{ $utils.numberToWan(task.train?.pendingPayment.amount) }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import { requestXml } from "../../assets/js/request";
var moment = require("moment");
export default {
    data() {
        return {
            //时间限制
            timeStart: null,
            timeStart2: null,
            timeStart3: null,
            timeStart4: null,

            windowHeight: "",
            allValue: 1,
            rankValue: 1,
            orderValue: 1,
            statisticsValue: 1,
            home: [],

            // 待办任务
            task: [],
            // 排行榜
            start: [],
            end: [],
            rank: [],
            value: 3,
            timeArr: [
                { label: "昨天", value: 1 },
                { label: "本周", value: 2 },
                { label: "本月", value: 3 },
                { label: "全年", value: 4 },
            ],
            // 平台动态
            news: [],
        };
    },
    mounted() {
        this.windowHeight = document.body.clientHeight - 430 + "px";

        this.start = moment().startOf("day").format("x") - 86400000;
        this.end = moment().endOf("day").format("x") - 86400000;
        this.getRank();
        this.getHome();
        this.getPayment();
        this.getOrder();
    },
    methods: {
        disabledDate(current) {
            return current && current < this.timeStart.endOf('');
        },
        disabledDate2(current) {
            return current && current < this.timeStart2.endOf('');
        },

        disabledDate3(current) {
            return current && current < this.timeStart3.endOf('');
        },
        disabledDate4(current) {
            return current && current < this.timeStart4.endOf('');
        },

        //更多 页面跳转
        goWeb(path) {
            this.$router.push({
                path: "/scrm_pc/" + path,
            });
        },
        // 获取首页数据
        getHome() {
            requestXml(
                "/scrm/index/dataOverview",
                "POST",
                (res) => {
                    console.log(res);
                    this.home = res;
                },
                { start: this.start, end: this.end }
            );
        },
        // 获取待办任务
        getPayment() {
            requestXml(
                "/scrm/index/paymentStatis",
                "POST",
                (res) => {
                    this.task = res;
                },
                { start: this.start, end: this.end }
            );
        },
        // 设置开始时间0:0:0 结束时间23:59:59
        setTime(time, type) {
            if (type == 1) {
                time.setHours(0);
                time.setMinutes(0);
                time.setSeconds(0);
            } else {
                time.setHours(23);
                time.setMinutes(59);
                time.setSeconds(59);
            }
            return time.getTime();
        },
        // 本周 本月 全年
        timeChange(val, type) {
            if (val == 1) {
                this.start = moment().startOf("day").format("x");
                this.end = moment().endOf("day").format("x");
            } else if (val == 2) {
                this.start = moment().startOf("week").add(1, "day").format("x");
                this.end = moment().endOf("week").add(1, "day").format("x");
            } else if (val == 3) {
                this.start = moment().startOf("month").format("x");
                this.end = moment().endOf("month").format("x");
            } else {
                this.start = moment().startOf("year").format("x");
                this.end = moment().endOf("year").format("x");
            }
            if (type == 2) {
                this.getRank();
            } else if (type == 3) {
                this.getOrder();
            } else if (type == 1) {
                this.getHome();
            } else if (type == 4) {
                this.getPayment();
            }
        },
        onChange1(value, index) {
            this.allValue = ''
            if (index == 1) {
                this.start = this.setTime(value._d, 1);
                this.end = this.setTime(value._d, 2);
                this.getHome();
            }
            if (index == 2) {
                this.end = this.setTime(value._d, 1);
                this.getHome();
            }
        },
        onChange2(value, index) {
            this.rankValue = ''
            if (index == 1) {
                this.start = this.setTime(value._d, 1);
                this.end = this.setTime(value._d, 2);
                this.getRank();
            }
            if (index == 2) {
                this.end = this.setTime(value._d, 1);
                this.getRank();
            }
        },
        onChange3(value, index) {
            this.orderValue = ''
            if (index == 1) {
                this.start = this.setTime(value._d, 1);
                this.end = this.setTime(value._d, 2);
                this.getOrder();
            }
            if (index == 2) {
                this.end = this.setTime(value._d, 1);
                this.getOrder();
            }
        },
        onChange4(value, index) {
            this.statisticsValue = ''
            if (index == 1) {
                this.start = this.setTime(value._d, 1);
                this.end = this.setTime(value._d, 2);
                this.getPayment();
            }
            if (index == 2) {
                this.end = this.setTime(value._d, 1);
                this.getPayment();
            }
        },
        // 获取排行榜
        getRank() {
            requestXml(
                "/scrm/index/salesRanking",
                "POST",
                (res) => {
                    this.rank = res;

                    this.$nextTick(() => {
                        let mainDom = document.getElementById('main11');
                        if (mainDom) {

                            let mainDom = document.getElementById('main11');

                            let myChart = echarts.init(mainDom); // 注意：如果你直接传入this.$refs.myChart，它可能是一个Vue组件实例，所以你需要使用.$el来访问其DOM元素  
                            // 或者如果myChartDom直接就是DOM元素，则不需要.$el  
                            // let myChart = echarts.init(myChartDom);  
                            // 配置图表等...  

                            var option;

                            option = {
                                tooltip: {
                                    trigger: 'item',
                                    formatter: function (params) {
                                        return '新增客户数' + params.data.addnum +
                                            ' <br/> 首次成交数' + params.data.dealnum +
                                            ' <br/> 首次成交率' + params.data.value + '%'
                                    }
                                },
                                series: [
                                    {
                                        name: '首次成交数',
                                        type: 'funnel',
                                        left: '10%',
                                        top: 60,
                                        bottom: 60,
                                        width: '80%',
                                        min: 10,
                                        max: 100,
                                        minSize: '10%',
                                        maxSize: '100%',
                                        sort: 'descending',
                                        gap: 2,
                                        label: {
                                            show: true,
                                            position: 'inside',
                                            formatter: '{b} : {c}%',
                                        },

                                        labelLine: {
                                            length: 10,
                                            formatter: '{b} : {c}%',
                                            lineStyle: {
                                                width: 1,
                                                type: 'solid'
                                            },

                                        },
                                        itemStyle: {
                                            borderColor: '#fff',
                                            borderWidth: 1,
                                            opacity: 0.7,
                                        },
                                        data: this.rank
                                    }
                                ]
                            };

                            option && myChart.setOption(option);
                            // 初始化ECharts  
                        } else {
                            console.error('DOM元素不存在！11111111');
                        }
                    });
                },
                { start: this.start, end: this.end }
            );
        },
        // 获取订单统计
        getOrder() {
            requestXml("/scrm/index/orderStatis", "POST", (res) => {
                this.news = res;
            }, { start: this.start, end: this.end })
        },
    },
};
</script>
<style>
.page.home {
    background-image: url('../../assets/imgs/wap/bg.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% auto;
    padding-bottom: 50px;
}

/deep/ .ant-select-selection--single {
    width: 80px;
}

.page.home>.title {
    line-height: 80px;
    height: 80px;
    text-align: center;
    color: #fff;
}

.home .ant-calendar-picker {
    margin: 0 2rem;
}

.header_top .right {
    margin: 0 auto;
    margin-bottom: 1.5rem;
}

.header_top /deep/ .ant-input {
    width: 22rem;
    font-size: 12px;
    height: 25px;
    background: transparent !important;
    color: #fff;
    border: #fff 1px solid;
}

.header_top /deep/ .ant-input::placeholder {
    color: #fff;
    font-size: 12px;
    /* 将占位符文本颜色设置为灰色 */
}

.header_top /deep/ .ant-select-selection {
    height: 25px;
    line-height: 25px;
    background: transparent !important;
    color: #fff;
    border: #fff 1px solid;
}

.header_top /deep/ .ant-select-selection__rendered {
    line-height: 25px;
}

.header_top /deep/ .anticon svg {
    color: #fff;
}

.txt {
    font-size: 26px;
    float: left;
}


.header {
    width: calc(100% - 40px);
    padding-bottom: 1.5rem;
    background-color: #fff;
    margin: 0 20px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 10px 20px;
}

.client_con {
    width: 100%;
    padding-bottom: 1.5rem;
    background: #FFF5E9;
    display: flex;
    justify-content: space-around;
    padding: 10px;
    margin-bottom: 12px;
    flex-wrap: wrap;
}

.client_con_flex {
    font-weight: bold;
    font-size: 18px;
    color: #FF6C1C;
    text-align: center;
}

.client_con_flex .txt {
    font-weight: 500;
    font-size: 12px;
    color: #666666;
    text-align: center;
    display: block;
    width: 100%;
}

.ranking {
    background: #FFFFFF;
    border-radius: 30px 30px 0px 0px;
}

.ranking_con {
    position: relative;
}

.ranking_l {
    position: absolute;
    left: 6%;
    top: 55%;
    width: 100px;
}

.ranking_r {
    position: absolute;
    right: 6%;
    top: 55%;
    width: 100px;
}

.div_top /deep/ .ant-input,
.div_top1 /deep/ .ant-input {
    width: 22rem;
    height: 25px;
    background: #EEEEEE !important;
    color: #4873C0;
    border: none;
}


.div_top /deep/ .ant-select-selection,
.div_top1 /deep/ .ant-select-selection {
    height: 25px;
    line-height: 25px;
    background: #EEEEEE !important;
    color: #4873C0;
    border: none;
}

.div_top /deep/ .ant-select-selection__rendered,
.div_top1 /deep/ .ant-select-selection__rendered {
    line-height: 25px;
}

.div_top /deep/ .anticon svg,
.div_top1 /deep/ .anticon svg {
    color: #4873C0;
}

.div_top {
    padding: 0 15px;
    justify-content: space-between;
    height: 40px;
    line-height: 40px;
}

.div_top1 {
    justify-content: space-between;
    line-height: 30px;
    background: #FFFFFF;
    border-radius: 30px 30px 0px 0px;
    padding: 15px;
    height: 60px;
    width: 100%
}

.div_top .right {
    align-items: center;
}

.div_top .right .quick {
    display: inline-block;
    margin-right: 10px;
}


.title_small {
    font-weight: bold;
    color: #2D5FB9;
    height: 8rem;
    line-height: 8rem;
}

.title_small img {
    width: 3.2rem;
    height: 3.2rem;
    margin: 2.4rem 1rem 0 0;
}

.div_con {
    margin-top: 2rem;
    padding-bottom: 1.5rem;
    background-color: #fff;
}

.div_con1 {
    margin: 0 auto;
    margin-top: 2rem;
    padding-bottom: 1.5rem;
    background-color: #fff;
    width: 90%;
}

.div_con1 .or {
    color: #FF6D1B;
}
</style>